/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
export type AppointmentRecordingMetadataFragment = { __typename?: 'AppointmentRecording', id: string, state: Types.AppointmentRecordingState, createdAt: string };

export const AppointmentRecordingMetadataFragmentDoc = gql`
    fragment AppointmentRecordingMetadata on AppointmentRecording {
  id
  state
  createdAt
}
    `;