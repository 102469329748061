// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchService } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { getHistoryIds } from '@app/modules/problems/shared/problems-utils';
import { Problem } from '@app/modules/problems/shared/problems.type';
import { mapTaskAssigneeSearchResultToEntity } from '@app/modules/todo/shared/todo-api-mappers';

import {
  deleteServiceTicketItemResponse,
  mapSummaryAddendumResponseToEntity,
  mapSummaryAppointmentResponseToEntity,
  mapSummaryResponseToEntity,
  mapToSummaryAddendumUpdateRequest,
  mapToSummaryAssessedProblemsUpdateRequest,
  mapToSummaryProblemsRequest,
  mapToSummarySignUpdateRequest,
  startVisitFromNoteResponse,
} from './summaries-api-mappers';
import { SummaryAddendumResponse, SummaryResponse } from './summaries-api.type';
import {
  SummaryFamilyHealthHistoryUpdateData,
  SummaryHealthBackgroundUpdateData,
  SummaryHealthGoalsUpdateData,
  SummaryMedicationsUpdateData,
  SummaryPatientAllergiesUpdateData,
  SummaryUpdateRequestData,
  SummaryVaccinationsUpdateData,
} from './summaries-update.type';
import { Summary, SummaryProblem } from './summaries.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { CoSignersQueryService } from '@app/modules/todo/graphql/co-signers.onelife.generated';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';

type SummariesSubRoute = 'summaries' | 'patients';
type SummariesSuffix = 'summaries' | 'sign' | 'summary_addendums' | 'redact';
type AddendumContent = string;
const adminRoute = '/v2/admin';

const summariesRoute = (
  patientId: number,
  subRoute: SummariesSubRoute,
  summaryId?: number,
  suffix?: SummariesSuffix,
) => {
  let resultRoute = `${adminRoute}/${subRoute}`;

  if (summaryId) {
    resultRoute = `${resultRoute}/${summaryId}`;
  } else {
    resultRoute = `${resultRoute}/${patientId}`;
  }

  if (suffix) {
    resultRoute = `${resultRoute}/${suffix}`;
  }
  if (summaryId && patientId) {
    resultRoute = `${resultRoute}?patientId=${patientId}`;
  }

  return resultRoute;
};

@Injectable()
export class SummariesApiService {
  constructor(
    private api: ApiService,
    private searchService: SearchService,
    private config: ConfigService,
    private apollo: Apollo,
    private launchDarklyService: LaunchDarklyService,
    private coSignQueryService: CoSignersQueryService,
  ) {}

  get(patientId: number, summaryId: number) {
    return this.api
      .get<SummaryResponse>(summariesRoute(patientId, 'summaries', summaryId))
      .pipe(map(mapSummaryResponseToEntity));
  }

  save(patientId: number, noteTypeId: number) {
    return this.api
      .save<SummaryResponse>(
        summariesRoute(patientId, 'patients', null, 'summaries'),
        {
          summary: {
            note: {
              note_type_id: noteTypeId,
            },
          },
        },
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  update(
    patientId: number,
    summaryId: number,
    /* Update data should conform to one of the update request data types. */
    data: Partial<SummaryUpdateRequestData>,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        data,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  sign(patientId: number, summaryId: number, data: Summary) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId, 'sign'),
        mapToSummarySignUpdateRequest(data),
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  delete(patientId: number, summaryId: number) {
    return this.api
      .delete<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  redact(patientId: number, summaryId: number) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId, 'redact'),
        null,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  searchCoSign(text: string): Observable<any> {
    const useBackendGraphQL = this.launchDarklyService.variation<boolean>(
      FeatureFlagNames.osProxySummaries,
      false,
    );

    if (useBackendGraphQL) {
      return this.coSignQueryService
        .fetch({
          name: text,
        })
        .pipe(
          map(response => {
            return response.data.coSigners.map(assignee => {
              return {
                id: parseInt(assignee.databaseId),
                identifier: assignee.identifier,
                name: assignee.name,
                role: assignee.role,
                outUntil: assignee.outUntil,
                label: assignee.label,
              };
            });
          }),
        );
    } else {
      const query = new QueryBuilder(
        'multi_match_with_fields_v6_strategy',
      ).build(text, {
        size: '8',
        fields: ['name'],
        operator: 'and',
        sort: ['_score', 'name.keyword'],
        filter: {
          bool: {
            must: [{ term: { doctype: 'internal_user' } }],
            _name: 'InternalUser',
          },
        },
        index: [this.config.searchIndex('task_assignees')],
      });

      return this.searchService.search(query).pipe(
        map(response => {
          const hits = response.hits || {};
          const items = hits.hits || [];
          return items.map(mapTaskAssigneeSearchResultToEntity);
        }),
      );
    }
  }

  saveAddendum(summaryId: number, data: AddendumContent) {
    return this.api
      .save<SummaryAddendumResponse>(
        summariesRoute(null, 'summaries', summaryId, 'summary_addendums'),
        mapToSummaryAddendumUpdateRequest(summaryId, data),
      )
      .pipe(map(mapSummaryAddendumResponseToEntity));
  }

  linkPatientAllergies(
    patientId: number,
    summaryId: number,
    data: SummaryPatientAllergiesUpdateData,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        data,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  linkProblems(patientId: number, summaryId: number, data: Problem[]) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        mapToSummaryProblemsRequest(data),
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  linkAssessedProblems(
    patientId: number,
    summaryId: number,
    assessedProblems: SummaryProblem[],
    historyId: number,
    problemId: number,
    hasAssessedProblem: boolean,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        mapToSummaryAssessedProblemsUpdateRequest(
          getHistoryIds(
            assessedProblems,
            historyId,
            problemId,
            !!hasAssessedProblem,
          ),
        ),
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  linkFamilyHealthHistory(
    patientId: number,
    summaryId: number,
    data: SummaryFamilyHealthHistoryUpdateData,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        data,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  linkHealthGoals(
    patientId: number,
    summaryId: number,
    data: SummaryHealthGoalsUpdateData,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        data,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  linkHealthBackground(
    patientId: number,
    summaryId: number,
    data: SummaryHealthBackgroundUpdateData,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        data,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  linkMedications(
    patientId: number,
    summaryId: number,
    data: SummaryMedicationsUpdateData,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        data,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  linkVaccinations(
    patientId: number,
    summaryId: number,
    data: SummaryVaccinationsUpdateData,
  ) {
    return this.api
      .update<SummaryResponse>(
        summariesRoute(patientId, 'summaries', summaryId),
        data,
      )
      .pipe(map(mapSummaryResponseToEntity));
  }

  deleteServiceTicketItem(serviceTicketItemId: number) {
    return this.apollo
      .use('onelife')
      .mutate({
        mutation: gql`
          mutation deleteServiceTicketItem($id: ID!) {
            deleteServiceTicketItem(input: { id: $id }) {
              success
              errors
            }
          }
        `,
        variables: { id: serviceTicketItemId.toString() },
      })
      .pipe(
        map(resp => resp.data),
        map(deleteServiceTicketItemResponse),
      );
  }

  startVisitFromNote(appointmentId: number, event: string) {
    return this.apollo
      .use('onelife')
      .mutate({
        mutation: gql`
          mutation TriggerAppointment($id: ID!, $event: String!) {
            triggerAppointment(input: { id: $id, event: $event }) {
              success
              errors
            }
          }
        `,
        variables: { id: appointmentId.toString(), event: event },
      })
      .pipe(
        map(resp => resp.data),
        map(startVisitFromNoteResponse),
      );
  }

  // Created to make sure appointment exist in arrived state so user can start visit from note
  getCurrentAppointment(summaryId: number) {
    return this.apollo
      .use('onelife')
      .query<SummaryResponse>({
        query: gql`
          query Summary($id: ID!) {
            summary(id: $id) {
              appointment: visitAppointment {
                id
                reason
                state
              }
            }
          }
        `,
        variables: { id: summaryId.toString() },
      })
      .pipe(
        map(resp => resp.data),
        map(summary => ({
          id: summary.appointment?.id,
          state: summary.appointment?.state,
          reason: summary.appointment?.reason,
        })),
      )
      .pipe(map(mapSummaryAppointmentResponseToEntity));
  }
}
