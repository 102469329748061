import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { summaryIsUnsignedStartedAppointment } from '@app/features/summaries/shared/summaries-utils';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { map, mergeMap, Observable, of, take } from 'rxjs';
import {
  HealthscribeRecorderService,
  RECORDING_STATE,
} from '../../healthscribe-recorder.service';

@Component({
  templateUrl: './readonly-recording-manager-container.component.html',
  selector: 'omg-readonly-recording-manager-container',
})
export class ReadonlyRecordingManagerContainerComponent implements OnInit {
  healthscribeInChartEnabled: boolean = false;
  recordingAppointmentId$: Observable<number | null>;

  private readonly activeSummaryUrlMatcher: RegExp =
    /chart\/summaries\/(?<summaryId>\d+)\/edit/;
  private destroyRef = inject(DestroyRef);

  private healthscribeRecorderService = inject(HealthscribeRecorderService);
  private recordingState$ = toObservable(
    this.healthscribeRecorderService.recordingState,
  );

  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private summarySelectors: SummariesSelectors,
  ) {}

  ngOnInit(): void {
    this.healthscribeInChartEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.healthscribeInChart,
      false,
    );

    if (this.healthscribeInChartEnabled) {
      this.recordingAppointmentId$ = this.recordingState$.pipe(
        mergeMap(recordingState => {
          if (this.shouldShowAppNavbarControl(recordingState)) {
            const match = this.activeSummaryUrlMatcher.exec(this.router.url);
            const summaryIdString = match?.groups?.summaryId;
            const summaryId = summaryIdString
              ? parseInt(summaryIdString, 10)
              : null;

            if (summaryId) {
              return this.summarySelectors.getSummaryById(summaryId).pipe(
                take(1),
                map((summary: Summary | null) => {
                  // convert summary to appointment id
                  return summary?.appointment &&
                    summaryIsUnsignedStartedAppointment(summary)
                    ? summary.appointment.id
                    : null;
                }),
              );
            }
          }
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
    }
  }

  private shouldShowAppNavbarControl(recordingState: RECORDING_STATE) {
    return [
      RECORDING_STATE.STARTING,
      RECORDING_STATE.IN_PROGRESS,
      RECORDING_STATE.PAUSED,
    ].includes(recordingState);
  }
}
