import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { Patient } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { VisitProcedure } from '@app/features/service-ticket/shared/visit-procedure.type';
import { VisitProcedureActions } from '@app/features/service-ticket/store/visit-procedure.actions';
import { VisitProcedureSelectors } from '@app/features/service-ticket/store/visit-procedure.selectors';
import { Commentable } from '@app/modules/comments/shared/comments.type';
import { FaxDocumentFormComponent } from '@app/modules/note/components/fax-document-form/fax-document-form.component';
import { PrintableNoteComponent } from '@app/modules/note/components/printable-note/printable-note.component';
import { Note } from '@app/modules/note/shared/note.type';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { vitalTypes } from '@app/modules/vitals-data/shared/vitals-data.type';
import { CollapseDirective } from '@app/shared';
import { DialogService } from '@app/shared/components/dialog';
import { ToastMessageService } from '@app/shared/components/toast';
import { FocusProviderDirective } from '@app/shared/directives/focus/focus-provider.directive';
import { filterTruthy } from '@app/utils';

import { visitProcedureTimeBasedLabel } from '../../../service-ticket/shared/visit-procedure.utils';
import {
  isFinishedCosignTodo,
  isWalkinVisit,
} from '../../shared/summaries-utils';
import {
  Summary,
  SummaryAddendum,
  SummaryTodo,
} from '../../shared/summaries.type';
import { SummariesActions } from '../../store/summaries.actions';
import { SummariesSelectors } from '../../store/summaries.selectors';
import { SummarySidebarComponent } from '../summary-sidebar/summary-sidebar.component';
import { GetRecordingInsightsQuery } from '../summaries/get-recordings.onelife.generated';

@Component({
  selector: 'omg-signed-summary',
  templateUrl: './signed-summary.component.html',
  styleUrls: ['./signed-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignedSummaryComponent implements OnInit, OnDestroy {
  @Input() summary: Summary;
  @Input()
  appointmentRecording: GetRecordingInsightsQuery['appointmentRecording'];
  @Input()
  appointmentRecordingIsLoading: boolean;
  @Input() todo: SummaryTodo;
  @Input() commentsCollapseRef: any;
  @Input() patient: Patient;
  @Input() profilePrimaryName: string;
  @Input() hasIncompleteCosignTodo: boolean;
  @Input() note: Note;
  @Output() refreshTimeline = new EventEmitter();

  @ViewChild('redactConfirmRef', { static: true })
  redactConfirmRef: CollapseDirective;
  @ViewChild(PrintableNoteComponent)
  printableNote: PrintableNoteComponent | undefined;

  @ViewChild(SummarySidebarComponent)
  summarySidebar: SummarySidebarComponent;

  submitInProgress = false;
  savingError = false;
  showAddendumForm = false;
  addendumText = 'Add Stuff';
  iframeWindow: HTMLIFrameElement;
  items: MenuItem[];
  form: UntypedFormGroup;
  addendumSignButtonText = '';
  addendumAnalyticsSubcomponent: string;
  canRedactNote = false;
  showGrowthPercentiles: boolean;
  showServiceTicketDeleteError = false;
  visitProcedure$: Observable<VisitProcedure>;

  messages = {
    cosignTaskCompleted: 'Co-sign task completed',
    redactError: 'Summary could not be redacted',
    redactSuccess: 'Summary has been redacted',
    savingFailedMessage:
      'Note addendum could not be saved. Please email helpdesk@onemedical.com if this issue persists',
  };

  get note$(): Observable<Note> {
    return of(this.note);
  }

  get hasDocuments(): boolean {
    return !!this.note.documents && this.note.documents.length > 0;
  }

  get commentable(): Commentable {
    return {
      ...this.summary.note,
      commentableType: 'summary',
    };
  }

  timeBasedVisitLabel: (vp: VisitProcedure) => string =
    visitProcedureTimeBasedLabel;

  private unsubscribe = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastMessageService,
    private summariesActions: SummariesActions,
    private dialogService: DialogService,
    private summariesSelectors: SummariesSelectors,
    private todoActions: TodoActions,
    private visitProcedureActions: VisitProcedureActions,
    private visitProcedureSelectors: VisitProcedureSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.loadFeatureFlags();
    this.setAddendumText();
    this.setSplitButtonItems();
    this.buildForm();

    this.showGrowthPercentiles = this.summaryHasAnyGrowthPercentile();

    if (this.summary.visitProcedureId) {
      this.visitProcedureActions.getById({ id: this.summary.visitProcedureId });
      this.visitProcedure$ = this.visitProcedureSelectors.getById(
        this.summary.visitProcedureId,
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getServiceTicketButtonText(visitProcedure: VisitProcedure): string {
    const isLocked =
      visitProcedure?.serviceTicket?.serviceTicketTransmission?.locked;
    const serviceTicketOpen =
      this.summarySidebar?.workspace()?.expanded &&
      this.summarySidebar?.workspace()?.mode === 'service-ticket';
    if (serviceTicketOpen) {
      return 'Close Service Ticket';
    } else {
      return isLocked ? 'View Service Ticket' : 'Edit Service Ticket';
    }
  }

  summaryHasAnyGrowthPercentile(): boolean {
    const vitals = this.summary.vitals;
    const hasWtPct = !!vitals[vitalTypes.weightPercentile]?.value;
    const hasHtPct = !!vitals[vitalTypes.heightPercentile]?.value;
    const hasHeadPct = !!vitals[vitalTypes.headCircumferencePercentile]?.value;
    const hasBmiPct = !!vitals[vitalTypes.bmiPercentile]?.value;

    return hasWtPct || hasHtPct || hasHeadPct || hasBmiPct;
  }

  openFaxForm() {
    this.dialogService.open(FaxDocumentFormComponent, {
      autoFocus: true,
      data: {
        noteId: this.summary.note.id,
      },
    });
  }

  onClick(focus: FocusProviderDirective) {
    this.toggleAddendumForm();
    focus.setFocus('addendumFocus-add');
  }

  onCommentUpdate(update: 'add' | 'remove') {
    this.summariesActions.updateTotalCommentCount({
      ...this.summary,
      note: {
        ...this.summary.note,
        totalComments:
          update === 'add'
            ? this.summary.note.totalComments + 1
            : this.summary.note.totalComments - 1,
      },
    });
  }

  createAddendum() {
    const addendumContent = this.form.get('addendumContent')?.value;
    this.savingError = false;
    this.summariesActions.saveAddendum(addendumContent);

    combineLatest(
      this.summariesSelectors.loading,
      this.summariesSelectors.error,
      this.summariesSelectors.currentSummary,
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([loading, error, selectedSummary]) => {
        const currentSummary = selectedSummary || {
          summaryAddendums: [],
        };
        const currentAddendums = currentSummary.summaryAddendums;
        this.submitInProgress = loading;
        this.savingError = error;

        if (
          !this.submitInProgress &&
          !this.savingError &&
          currentAddendums.length > 0
        ) {
          this.updateCosign(
            currentAddendums[currentAddendums.length - 1],
            this.todo.id,
          );
          this.closeAddendumForm();
          this.unsubscribe.next();
        }
      });
  }

  updateCosign(addendum: SummaryAddendum, todoId: number) {
    const finishedCosignTodos = addendum.summary.todos.filter(todo =>
      isFinishedCosignTodo(todo, todoId),
    );

    if (this.hasIncompleteCosignTodo && finishedCosignTodos.length) {
      this.showCompletedCosign(finishedCosignTodos);
    }
  }

  showCompletedCosign(finishedCosignTodos: Todo[]) {
    this.toastService.add({
      severity: 'success',
      detail: this.messages.cosignTaskCompleted,
    });
    this.hasIncompleteCosignTodo = false;
    this.setAddendumText();
    // dispatch refresh timeline
    this.todoActions.updateTodo(finishedCosignTodos[0]);
  }

  toggleAddendumForm() {
    this.showAddendumForm = !this.showAddendumForm;
  }

  closeAddendumForm() {
    this.form.reset();
    this.toggleAddendumForm();
  }

  setAddendumText() {
    if (this.hasIncompleteCosignTodo) {
      this.addendumText = 'Co-Signature';
      this.addendumSignButtonText = 'Co-Sign Note';
      this.addendumAnalyticsSubcomponent = 'Cosignature';
    } else {
      this.addendumText = 'Addendum';
      this.addendumSignButtonText = 'Sign Addendum';
      this.addendumAnalyticsSubcomponent = 'Addendum';
    }
  }

  print() {
    if (this.printableNote) {
      this.printableNote.openPrintDialog();
    }
  }

  redact() {
    this.redactConfirmRef.toggle();
    this.summariesActions.redactSummary({
      id: this.summary.id,
      changes: {
        patientId: this.patient.id,
        summaryId: this.summary.id,
      },
    });

    this.summariesSelectors.loading
      .pipe(
        filter(loading => !loading),
        withLatestFrom(this.summariesSelectors.error),
        takeUntil(this.unsubscribe),
      )
      .subscribe(([_, error]) => {
        if (error) {
          this.toastService.add({
            severity: 'warn',
            detail: this.messages.redactError,
          });
        } else {
          this.toastService.add({
            severity: 'success',
            detail: this.messages.redactSuccess,
          });
          this.unsubscribe.next();
        }
      });
  }

  removeServiceTicketItems(serviceTicketItem) {
    this.summariesActions.deleteServiceTicketItem({
      serviceTicketItemId: serviceTicketItem.id,
      summary: this.summary,
    });

    this.summariesSelectors.loading
      .pipe(
        filter(loading => !loading),
        withLatestFrom(this.summariesSelectors.error),
        tap(([_, error]) => {
          if (error) {
            this.showServiceTicketDeleteError = true;
          }
        }),
        take(1),
      )
      .subscribe();
  }

  toggleServiceTicket(): void {
    this.summarySidebar.toggle('service-ticket');
  }

  displayServiceTicket(): void {
    this.summarySidebar.display('service-ticket');
  }

  displayHealthScribeSummary(): void {
    this.summarySidebar.display('healthscribe-transcript');
  }

  get summaryIsWalkinVisit(): Boolean {
    return isWalkinVisit(this.summary.noteType);
  }

  private loadFeatureFlags() {
    this.launchDarklyService
      .variation$(FeatureFlagNames.noteRedact, false)
      .pipe(filterTruthy(), take(1))
      .subscribe((value: boolean) => (this.canRedactNote = value));
  }

  private setSplitButtonItems() {
    this.items = [
      {
        label: 'Fax',
        command: () => this.openFaxForm(),
      },
      {
        label: 'Print',
        command: () => this.print(),
      },
    ];

    if (this.canRedactNote) {
      this.items.push({
        label: 'Redact',
        command: () => {
          this.redactConfirmRef.toggle();
        },
      });
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      addendumContent: new UntypedFormControl('', Validators.required),
    });
  }

  get displayAfterVisitGuidence() {
    const recommendation = this.summary.summaryProviderRecommendation;
    return recommendation?.s3Pointers?.length || recommendation?.body?.trim();
  }
}
