import { inject, Injectable } from '@angular/core';
import { windowToken } from '@app/shared/window/window.service';
import {
  IMediaRecorder,
  IMediaRecorderOptions,
  MediaRecorder as ExtendableMediaRecorder,
} from 'extendable-media-recorder';
import { from, fromEvent, map, mergeMap, Observable, startWith } from 'rxjs';

/**
 * Mock service so we don't have to mock globals.
 */
@Injectable({
  providedIn: 'root',
})
export class MediaRecorderApiService {
  private window = inject<Window>(windowToken);

  isSupported(): boolean {
    return 'MediaRecorder' in this.window && Boolean(this.window.MediaRecorder);
  }

  async getMediaStream(
    constraints: MediaStreamConstraints = {},
  ): Promise<MediaStream> {
    return this.window.navigator.mediaDevices.getUserMedia(constraints);
  }

  newMediaRecorder(
    mediaStream: MediaStream,
    options: IMediaRecorderOptions = {},
  ): IMediaRecorder {
    return new ExtendableMediaRecorder(mediaStream, options);
  }

  getPermissionState$(name: PermissionName): Observable<PermissionState> {
    return from(this.window.navigator.permissions.query({ name })).pipe(
      mergeMap((permissionStatus: PermissionStatus) => {
        return fromEvent(permissionStatus, 'change').pipe(
          map(event => event?.target?.['state'] as PermissionState),
          startWith(permissionStatus.state),
        );
      }),
    );
  }
}
