import { GetRecordingInsightsQuery } from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';
import { differenceInDays, parseISO } from 'date-fns';

export type AppointmentRecording =
  GetRecordingInsightsQuery['appointmentRecording'];

export const RecordingShelfLifeDays = 7;

export function recordingExpirationString(
  recording?: AppointmentRecording,
): string {
  if (!recording) {
    return `Expires in ${RecordingShelfLifeDays} days`;
  }
  const start = parseISO(recording.createdAt);
  const end = new Date();
  const difference = differenceInDays(end, start);
  const remaining = RecordingShelfLifeDays - difference;
  if (remaining > 1) {
    return `Expires in ${remaining} days`;
  } else if (remaining === 1) {
    return 'Expires in 1 day';
  } else {
    return 'Expires today';
  }
}
