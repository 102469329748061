import { Component, Input, ViewChild } from '@angular/core';
import { PopoverComponent } from '@app/shared/components/popover';

@Component({
  selector: 'omg-non-dispensable-popover',
  templateUrl: './non-dispensable-popover.component.html',
  styleUrls: ['./non-dispensable-popover.component.scss'],
})
export class NonDispensablePopoverComponent {
  @Input() availabilityStatus: string;

  @ViewChild(PopoverComponent)
  popover: PopoverComponent;

  get availabilityStatusLabel(): string | null {
    if (this.availabilityStatus === 'currently_unavailable') {
      return 'Possible Delay';
    } else if (this.availabilityStatus === 'does_not_fill') {
      return 'Does Not Fill';
    }
    return null;
  }

  get popoverText(): string | null {
    if (this.availabilityStatus === 'currently_unavailable') {
      return 'Due to ongoing supply changes, there may be delays in fill for these medications with Amazon Pharmacy.';
    } else if (this.availabilityStatus === 'does_not_fill') {
      return 'Medication is not available to order from Amazon Pharmacy. This includes schedule II controlled substances, liquid suspensions, and more.';
    }
    return null;
  }

  get learnMoreLink(): string | null {
    if (this.availabilityStatus === 'currently_unavailable') {
      return 'https://onemedical-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a145e000001JEHWAA4/page/a128W00000CH8gGQAT';
    } else if (this.availabilityStatus === 'does_not_fill') {
      return 'https://www.amazon.com/gp/help/customer/display.html?nodeId=TXqDwXw9QFQ2O3Ncpn#GUID-1AB9A3F9-717C-4A83-8DFA-9390BB1711B3__SECTION_37A74D4E89B5439098BF81A32B0CC241';
    }
    return null;
  }
}
