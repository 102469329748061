import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { filter, lastValueFrom } from 'rxjs';
import { ProblemAttachmentsService } from '@app/modules/problems/shared/problem-attachments.service';
import { ProblemSelectors } from '@app/modules/problems/store/problems.selectors';
import { ProblemAttachmentAttachmentEnum as ProblemAttachmentType } from '../../../../../graphql/onelife.type';
import {
  ProblemReference,
  ProblemReferences,
  toProblemId,
  toProblemReferences,
} from './problem-reference';
import { isEqual } from 'lodash';

interface ProblemSelectorForm {
  selected: FormControl<ProblemReference[]>;
}

/**
 * Sample workflow:
 * 1. Form dropdown options populated with active patient problems (from REST api).
 * 2. User selects problems to link the document to.
 * 3. GraphQL mutations issued to link/unlink document to/from problems.
 */
@Component({
  selector: 'omg-problem-selector',
  templateUrl: './problem-selector.component.html',
  styleUrls: ['./problem-selector.component.scss'],
})
export class ProblemSelectorComponent implements OnInit {
  /**
   * the ID of the document in question. Used to fetch all associations.
   */
  @Input()
  documentId: number;

  /**
   * EventEmitter that passes along the current state of selected Problems
   * This EventEmitter should be used by the parent component to perform state updates
   */
  @Output()
  readonly selectionChanged = new EventEmitter<ProblemReference[]>();

  /**
   * This EventEmitter is informational only and is triggered when a Problem is unselected
   */
  @Output()
  readonly trackSelectionRemoved = new EventEmitter<void>();

  /**
   * This EventEmitter is informational only and is triggered when a Problem is selected
   */
  @Output()
  readonly trackSelectionAdded = new EventEmitter<void>();

  /**
   * This EventEmitter is informational only and is triggered when the typeahead is opened.
   */
  @Output()
  readonly trackTypeaheadOpened = new EventEmitter<void>();

  /**
   * This EventEmitter is informational only and is triggered when the typeahead is closed.
   */
  @Output()
  readonly trackTypeaheadClosed = new EventEmitter<void>();

  readonly form: FormGroup<ProblemSelectorForm>;

  /**
   * References to active patient problems.
   */
  readonly problems: ProblemReferences;

  constructor(
    private destroyRef: DestroyRef,
    private problemSelectors: ProblemSelectors,
    private problemAttachmentService: ProblemAttachmentsService,
    formBuilder: FormBuilder,
  ) {
    this.problems = toProblemReferences(this.problemSelectors.activeProblems);
    this.form = formBuilder.group<ProblemSelectorForm>({
      selected: new FormControl<ProblemReference[]>([], { nonNullable: true }),
    });
  }

  ngOnInit(): void {
    this.initSelectionSubscription();
  }

  /**
   * update the form selection if the set of problems associated with the document changes
   */
  private initSelectionSubscription(): void {
    this.problemAttachmentService
      .getProblemIdsByAttachment(
        ProblemAttachmentType.Document,
        this.documentId,
      )
      .pipe(
        filter(problemIds => {
          const setA = this.form.controls.selected.value
            .map(toProblemId)
            .toSorted();
          const setB = problemIds.toSorted();
          return !isEqual(setA, setB);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(problemIds => {
        const selection = this.problems.select(problemIds);
        this.form.controls.selected.setValue(selection);
      });
  }

  async onAdd(problem: ProblemReference): Promise<void> {
    const result$ = this.problemAttachmentService.createAttachment(
      problem.id,
      ProblemAttachmentType.Document,
      this.documentId,
    );
    const result = await lastValueFrom(result$);
    if (!result.errors) {
      this.trackSelectionAdded.emit();
    }
  }

  async onRemove(problem: ProblemReference): Promise<void> {
    const result$ = this.problemAttachmentService.unlinkAttachment(
      problem.id,
      ProblemAttachmentType.Document,
      this.documentId,
    );
    const result = await lastValueFrom(result$);
    if (!result.errors) {
      this.trackSelectionRemoved.emit();
    }
  }

  onChange(problems: ProblemReference[] = []): void {
    this.selectionChanged.emit(problems);
  }

  onOpen(): void {
    this.trackTypeaheadOpened.emit();
  }

  onClose(): void {
    this.trackTypeaheadClosed.emit();
  }
}
