import {
  Component,
  input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  GetAppointmentRecordingQuery,
  GetAppointmentRecordingQueryService,
  GetAppointmentRecordingQueryVariables,
} from '../../graphql/get-appointment-recording.onelife.generated';
import { map, Observable, share, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { QueryRef } from 'apollo-angular';
import { differenceInDays } from 'date-fns';
import { startWith } from 'rxjs/operators';
import { AppointmentRecordingMetadataFragment } from '../../graphql/appointment-recording-metadata.onelife.generated';
import { NgClass } from '@angular/common';
import { SharedModule } from '@app/shared';

interface HealthScribeBannerContent {
  iconClass: Record<string, boolean>;
  bannerText: string;
  buttonClass: 'flat' | 'primary';
  link: string;
}

@Component({
  selector: 'omg-healthscribe-banner-v1',
  templateUrl: './healthscribe-banner.component.html',
  styleUrls: ['./healthscribe-banner.component.scss'],
  imports: [NgClass, SharedModule],
  standalone: true,
})
export class HealthscribeBannerV1Component
  implements OnInit, OnDestroy, OnChanges
{
  appointmentId = input.required<number>();

  bannerContent$: Observable<HealthScribeBannerContent | null>;
  bannerVisible$: Observable<boolean>;

  private readonly defaultPollIntervalMs: number = 60000;
  private readonly recordingStatePollIntervalMs: number = 30000;
  private readonly transcribingStatePollIntervalMs: number = 10000;

  private appointmentRecordingQueryRef:
    | QueryRef<
        GetAppointmentRecordingQuery,
        GetAppointmentRecordingQueryVariables
      >
    | undefined;

  get defaultBannerContent(): HealthScribeBannerContent {
    return {
      iconClass: { 'icon-microphone': true },
      bannerText: 'Use HealthScribe to record and summarize visit',
      buttonClass: 'primary',
      link: `${environment.adminApp.host}/scribe/for-appointment?appointmentId=${this.appointmentId()}`,
    };
  }

  constructor(
    private getAppointmentRecordingQueryService: GetAppointmentRecordingQueryService,
  ) {}

  ngOnInit(): void {
    this.initializeQueryRef();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appointmentId) {
      if (this.appointmentRecordingQueryRef) {
        this.stopPollingForRecording();
        this.appointmentRecordingQueryRef.refetch({
          appointmentId: this.appointmentId().toString(),
        });
      } else {
        this.initializeQueryRef();
      }
    }
  }

  ngOnDestroy(): void {
    this.stopPollingForRecording();
  }

  onClick(link: string): void {
    link && window.open(link, '_healthscribe');
  }

  private initializeQueryRef() {
    this.appointmentRecordingQueryRef =
      this.getAppointmentRecordingQueryService.watch({
        appointmentId: this.appointmentId().toString(),
      });
    const sharedResult =
      this.appointmentRecordingQueryRef.valueChanges.pipe(share());
    this.bannerContent$ = sharedResult.pipe(
      tap(result => this.setPolling(result.data.appointment?.recording)),
      map(result =>
        this.mapToBannerContent(result.data.appointment?.recording),
      ),
    );
    this.bannerVisible$ = sharedResult.pipe(
      map(result => {
        if (!result.data.appointment?.recording) return true;

        const date = result.data.appointment.recording.createdAt;
        const difference = differenceInDays(new Date(), new Date(date));
        return difference <= 7;
      }),
      startWith(true),
    );
  }

  private mapToBannerContent(
    recording?: AppointmentRecordingMetadataFragment | null,
  ): HealthScribeBannerContent {
    if (recording) {
      switch (recording.state) {
        case 'ready_to_record':
          return {
            ...this.defaultBannerContent,
            link: this.recordingLink(recording.id),
          };
        case 'recording':
        case 'transcribing':
        case 'archived':
        case 'transcribing_failed':
        case 'transcribed':
          return {
            iconClass: { 'icon-magic': true },
            bannerText: 'View visit summary',
            buttonClass: 'flat',
            link: this.recordingLink(recording.id),
          };
      }
    }
    return this.defaultBannerContent;
  }

  private recordingLink(recordingId: string): string {
    return `${environment.adminApp.host}/scribe/record/${recordingId}`;
  }

  private setPolling(
    recording?: AppointmentRecordingMetadataFragment | null,
  ): void {
    if (!recording || recording?.state === 'ready_to_record') {
      this.pollForRecording(this.defaultPollIntervalMs);
    } else if (recording?.state === 'recording') {
      this.pollForRecording(this.recordingStatePollIntervalMs);
    } else if (recording?.state === 'transcribing') {
      this.pollForRecording(this.transcribingStatePollIntervalMs);
    } else {
      this.stopPollingForRecording();
    }
  }

  private pollForRecording(pollIntervalMs: number): void {
    this.appointmentRecordingQueryRef?.startPolling(pollIntervalMs);
  }

  private stopPollingForRecording(): void {
    this.appointmentRecordingQueryRef?.stopPolling();
  }
}
