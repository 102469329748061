import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';

import {
  PatientChartRootState,
  Timeline,
  Workspace,
} from './patient-chart.reducer';
import { SidebarMode } from '@app/features/summaries/components/summary-sidebar/summary-sidebar.component';

export const EXPAND_TIMELINE = '[PatientChart] Toggle Expanded Timeline';
export const EXPAND_TIMELINE_SUCCESS =
  '[PatientChart] Toggle Expanded Timeline Success';
export const EXPAND_TIMELINE_ERROR =
  '[PatientChart] Toggle Expanded Timeline Error';

export const EXPAND_WORKSPACE = '[PatientChart] Toggle Expanded Workspace';
export const EXPAND_WORKSPACE_SUCCESS =
  '[PatientChart] Toggle Expanded Workspace Success';
export const EXPAND_WORKSPACE_ERROR =
  '[PatientChart] Toggle Expanded Workspace Error';

type PatientChartErrorPayload = any;

export class ExpandTimeline implements FsaAction<Timeline> {
  readonly type = EXPAND_TIMELINE;

  constructor(public payload: Timeline) {}
}

export class ExpandTimelineSuccess implements FsaAction<Timeline> {
  readonly type = EXPAND_TIMELINE_SUCCESS;
  error = false;

  constructor(public payload: Timeline) {}
}

export class ExpandTimelineError implements FsaAction<any> {
  readonly type = EXPAND_TIMELINE_ERROR;
  error = true;

  constructor(public payload: PatientChartErrorPayload) {}
}

export class ExpandWorkspace implements FsaAction<Workspace> {
  readonly type = EXPAND_WORKSPACE;

  constructor(public payload: Workspace) {}
}

export class ExpandWorkspaceSuccess implements FsaAction<Workspace> {
  readonly type = EXPAND_WORKSPACE_SUCCESS;
  error = false;

  constructor(public payload: Workspace) {}
}

export class ExpandWorkspaceError implements FsaAction<any> {
  readonly type = EXPAND_WORKSPACE_ERROR;
  error = true;

  constructor(public payload: PatientChartErrorPayload) {}
}

export type PatientChartAction =
  | ExpandTimeline
  | ExpandTimelineSuccess
  | ExpandTimelineError
  | ExpandWorkspace
  | ExpandWorkspaceSuccess
  | ExpandWorkspaceError;

@Injectable({
  providedIn: 'root',
})
export class PatientChartActions {
  constructor(private store: Store<PatientChartRootState>) {}

  expandTimeline(expanded: boolean) {
    this.store.dispatch(new ExpandTimeline({ expanded }));
  }

  expandWorkspace(expanded: boolean, mode: SidebarMode = 'service-ticket') {
    this.store.dispatch(new ExpandWorkspace({ expanded, mode }));
  }
}
