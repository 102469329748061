@if (bannerVisible$ | ngrxPush) {
@if (bannerContent$ | ngrxPush; as bannerContent) {
<div om-layout
     om-layout-fill
     om-layout-align="space-around center"
     class="banner">
  <span class="om-icon"
        [ngClass]="bannerContent.iconClass"></span>
  <p class="banner-text">{{ bannerContent.bannerText }}</p>
  <button omgButton
          type="button"
          [variant]="bannerContent.buttonClass"
          (click)="onClick(bannerContent.link)"
          style="white-space: nowrap">
    Open HealthScribe
  </button>
</div>
}
}
