<div>
  @if (showHealthscribeBanner(summary) === 'v1' && summary.appointment?.id) {
  <omg-healthscribe-banner-v1 [appointmentId]="summary.appointment.id" />
  }

  @if (showHealthscribeBanner(summary) === 'v2' && summary.appointment?.id && !appointmentRecordingIsLoading) {
  <div class="healthscribe-v2-container padding-normal">
    @if (bannerState() !== 'recording') {
    <omg-healthscribe-banner-v2 [variant]="bannerVariant"
                                [recording]="appointmentRecording"
                                (startRecording)="startHealthScribeRecording()"
                                (dismiss)="dismissHealthScribe()"
                                (undoDismiss)="undoDismissHealthScribe()"
                                (viewSummary)="viewSummary.emit($event)"
                                (undoGenerate)="undoGenerateHealthScribe()" />
    }
    @if (bannerState() === 'recording' && summary.appointment?.id) {
    <omg-recording-manager mode="interactive"
                           [appointmentId]="summary.appointment.id.toString()"></omg-recording-manager>
    }
  </div>
  }
</div>
