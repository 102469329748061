// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NoteFax } from '@app/modules/note/shared/note.type';

type History = NoteFax[]; // Add any more types here like order transmissions

@Component({
  selector: 'omg-transmission-details',
  templateUrl: './transmission-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransmissionDetailsComponent {
  @Input() history: History;

  // this regex is used to process data received from the API,
  // and does not include user input
  // eslint-disable-next-line redos/no-vulnerable
  readonly emailToPattern = /^"?([^"]*)"? <.*>$/;

  trackByFn = (index, item) => item.id || index;

  emailRecipient(history: History) {
    return this.emailToPattern.exec((<any>history).to)[1];
  }
}
