import { computed, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Problem } from '@app/modules/problems/shared/problems.type';
import { Observable } from 'rxjs';
import { getProblemString } from '@app/modules/problems/shared/problems-utils';

export type ID = string;

export interface ProblemReference {
  id: ID;
  code: string;
  name: string;
  label: string;
}

export interface ProblemReferences {
  list: Signal<ProblemReference[]>;
  map: Signal<Map<ID, ProblemReference>>;
  select(ids: ID[]): ProblemReference[];
}

export function toProblemReferences(
  problems$: Observable<Problem[]>,
): ProblemReferences {
  const problems = toSignal(problems$, { initialValue: [] });
  const list = computed(() => problems().map(asProblemReference));
  const map = computed(() => mapById(list()));
  function select(ids: ID[]): ProblemReference[] {
    return ids
      .filter(id => map().has(id))
      .map(id => map().get(id) as ProblemReference);
  }
  return {
    list,
    map,
    select,
  };
}

export function toProblemId(problem: ProblemReference): ID {
  return problem.id;
}

export function asProblemReference(problem: Problem): ProblemReference {
  return {
    id: problem.id.toString(),
    code: problem.code || '',
    name: getProblemString(problem),
    label: problem.problemType.clinicalDescription,
  };
}

function mapById(references: ProblemReference[]): Map<ID, ProblemReference> {
  const map = new Map<ID, ProblemReference>();
  references.forEach(reference => map.set(reference.id, reference));
  return map;
}
