/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type CoSignersQueryVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
}>;


export type CoSignersQuery = { __typename?: 'Query', coSigners: Array<{ __typename?: 'TaskAssignee', databaseId: string, name: string, role: string, identifier: string, doctype: string, outUntil?: string | null, label: string }> };

export const CoSignersDocument = gql`
    query CoSigners($name: String!) {
  coSigners(name: $name) {
    databaseId
    name
    role
    identifier
    doctype
    outUntil
    label
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CoSignersQueryService extends Query<CoSignersQuery, CoSignersQueryVariables> {
    document = CoSignersDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }