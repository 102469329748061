<form [formGroup]="form">
  <label>Add Associated Problems</label>
  <omg-auto-complete #problemSelector
                     omgStopEvent
                     placeholder="Select from active problems"
                     formControlName="selected"
                     bindLabel="label"
                     [items]="problems.list()"
                     [hideClearAll]="true"
                     [multiple]="true"
                     (changeAutocomplete)="onChange($event)"
                     (opened)="onOpen()"
                     (closed)="onClose()"
                     (add)="onAdd($event)"
                     (remove)="onRemove($event)"
                     data-cy="problem-selector-input">
    <ng-template let-problem="optionData">
      <div om-layout
           om-layout-gutter>
        <div om-flex="80">
          {{ problem.name }}
        </div>
        <div om-flex> {{ problem.code }} </div>
      </div>
    </ng-template>

  </omg-auto-complete>
</form>
