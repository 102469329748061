<omg-popover placement="right"
             [enabled]="true"
             width="400"
             #tooltip>
  <div class="non-dispensable-popover">
    <omg-section-title>
      <div class="title">
        Amazon Pharmacy Medication Inventory
      </div>
    </omg-section-title>
    <div class="content">
      <div class="info">
        <div class="subtitle">{{ availabilityStatusLabel }}</div>
        <p>{{ popoverText }}</p>
      </div>
      <div class="footer">
        <a [href]="learnMoreLink"
           target="_blank"
           class="learn-more">
          Learn more <i class="pi pi-external-link"></i>
        </a>
      </div>
    </div>
  </div>
</omg-popover>
