import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Observable, of, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';
import { logoutPath } from '@app/core/auth/shared/auth-constants';
import { ThemeService } from '@app/core/theme/theme.service';
import { onemTheme } from '@app/core/theme/theme.type';
import { invoke } from '@app/utils';

@Component({
  selector: 'omg-app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
})
export class AppNavbarComponent implements OnInit, OnDestroy {
  @ViewChild('profileMenu', { static: true }) profileMenu: Menu;
  @ViewChild('templateManagerMenu', { static: true }) templateManagerMenu: Menu;
  primaryIdentityName: Observable<string>;
  templateManagerOptions: Observable<MenuItem[]>;
  profileOptions: Observable<MenuItem[]>;
  isEngineer$: Observable<boolean>;
  adminAppURL = `${environment.adminApp.host}/schedule/tasks/mine`;
  templatesURL = `${environment.templatesApp.host}`;
  papURL = `${environment.adminApp.host}/admin/pap-reporting`;

  private unsubscribe$ = new Subject<void>();
  private templateManagerMenuClickListener: any;
  private profileMenuClickListener: any;
  private profileMenuOpen = false;
  private templateManagerMenuOpen = false;
  activeThemeName$: Observable<string>;
  redirectTasksToSchedule: boolean;

  constructor(
    private profile: ProfileSelectors,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    public renderer: Renderer2,
    private themeService: ThemeService,
  ) {}

  ngOnInit() {
    this.primaryIdentityName = this.profile.primaryIdentityName;
    this.profileOptions = this.createProfileOptions();
    this.templateManagerOptions = this.createTemplateManagerOptions();
    this.isEngineer$ = this.profile.hasRole('engineer');

    this.activeThemeName$ = this.themeService.themeChange$.pipe(
      map(theme => theme.name),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  signout = () => {
    this.router.navigateByUrl(`/${logoutPath}`);
  };

  toggleProfileMenu($event: Event) {
    if (!this.profileMenuOpen) {
      this.showProfileMenu($event);
    } else {
      this.hideProfileMenu();
    }
    this.changeDetectorRef.detectChanges();
  }

  toggleTemplateManagerMenu($event: Event) {
    if (!this.templateManagerMenuOpen) {
      this.showTemplateManagerMenu($event);
    } else {
      this.hideTemplateManagerMenu();
    }
    this.changeDetectorRef.detectChanges();
  }

  toggleTheme() {
    this.activeThemeName$.pipe(take(1)).subscribe(() => {
      this.themeService.setTheme(onemTheme);
    });
  }

  private createTemplateManagerOptions() {
    return of([
      {
        label: 'Template Manager',
        command: () => window.open(this.templatesURL, '_blank'),
      },
      {
        label: 'Cervical Cancer Screening (Pap) Reporting',
        command: () => window.open(this.papURL, '_blank'),
      },
    ]);
  }

  private createProfileOptions() {
    return of([
      {
        label: 'Sign Out',
        command: this.signout,
      },
    ]);
  }

  private toggleProfileMenuOpen() {
    this.profileMenuOpen = !this.profileMenuOpen;
  }

  private toggleTemplateManagerMenuOpen() {
    this.templateManagerMenuOpen = !this.templateManagerMenuOpen;
  }

  private showProfileMenu($event: Event) {
    this.profileMenu.show($event);
    this.bindProfileMenuClickListener();
    setTimeout(() => {
      this.toggleProfileMenuOpen();
    });
  }

  private hideProfileMenu() {
    this.profileMenu.hide();
    this.toggleProfileMenuOpen();
    this.unbindProfileMenuClickListener();
  }

  private showTemplateManagerMenu($event: Event) {
    this.templateManagerMenu.show($event);
    this.bindTemplateManagerMenuClickListener();
    setTimeout(() => {
      this.toggleTemplateManagerMenuOpen();
    });
  }

  private hideTemplateManagerMenu() {
    this.templateManagerMenu.hide();
    this.toggleTemplateManagerMenuOpen();
    this.unbindTemplateManagerClickListener();
  }

  private bindProfileMenuClickListener() {
    if (!this.profileMenuClickListener) {
      this.profileMenuClickListener = this.renderer.listen(
        'document',
        'click',
        () => {
          if (this.profileMenuOpen) {
            this.hideProfileMenu();
            this.changeDetectorRef.markForCheck();
          }
        },
      );
    }
  }

  private unbindProfileMenuClickListener() {
    invoke('profileMenuClickListener', this);
    this.profileMenuClickListener = null;
  }

  private bindTemplateManagerMenuClickListener() {
    if (!this.templateManagerMenuClickListener) {
      this.templateManagerMenuClickListener = this.renderer.listen(
        'document',
        'click',
        () => {
          if (this.templateManagerMenuOpen) {
            this.hideTemplateManagerMenu();
            this.changeDetectorRef.markForCheck();
          }
        },
      );
    }
  }

  private unbindTemplateManagerClickListener() {
    invoke('templateManagerMenuClickListener', this);
    this.templateManagerMenuClickListener = null;
  }
}
