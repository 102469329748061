import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeRx } from '@app/features/change-rx/shared/change-rx.type';
import { ChangeRxActions } from '@app/features/change-rx/store/change-rx.actions';
import { ChangeRxSubmitAction } from '@app/features/change-rx/store/change-rx.reducer';
import { ChangeRxSelectors } from '@app/features/change-rx/store/change-rx.selectors';

import { Renewal } from '../../shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-renewals',
  templateUrl: './renewals.component.html',
  styleUrls: ['./renewals.component.scss'],
})
export class RenewalsComponent implements OnInit {
  renewals$: Observable<Renewal[]>;
  changeRxs$: Observable<ChangeRx[]>;

  loading$: Observable<boolean>;
  empty$: Observable<boolean>;
  cartComplete$: Observable<boolean>;
  changeRxSubmitAction$: Observable<ChangeRxSubmitAction>;

  constructor(
    private renewalActions: RenewalActions,
    private renewalSelectors: RenewalSelectors,
    private changeRxActions: ChangeRxActions,
    private changeRxSelectors: ChangeRxSelectors,
  ) {}

  ngOnInit() {
    this.changeRxActions.load();
    this.renewalActions.loadRenewalCart();

    this.renewals$ = this.renewalSelectors.entities;
    this.changeRxs$ = this.changeRxSelectors.entities;
    this.cartComplete$ = this.renewalSelectors.cartComplete;
    this.changeRxSubmitAction$ = this.changeRxSelectors.changeRxSubmitAction;

    this.empty$ = combineLatest([this.renewals$, this.changeRxs$]).pipe(
      map(([renewals, changeRxs]) => !renewals.length && !changeRxs.length),
    );

    this.loading$ = combineLatest([
      this.empty$,
      this.renewalSelectors.loading,
      this.changeRxSelectors.loading,
    ]).pipe(
      map(
        ([empty, loadingRenewals, loadingChangeRxs]) =>
          empty && (loadingRenewals || loadingChangeRxs),
      ),
    );
  }
}
