import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LoggerService } from '../logger';

import { httpStatus } from '@app/utils';
import { environment } from '@environments/environment';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FeatureFlagNames } from '../feature-flag/shared/feature-flag.type';

@Injectable()
export class ForbiddenInterceptorService implements HttpInterceptor {
  restrictedEndpointPatterns: RegExp[] = [
    new RegExp(/\/api\/v2\/admin\/patients\/\d+$/),
  ];

  constructor(
    private logger: LoggerService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleForbiddenError(request, error),
        ),
      );
  }

  private handleForbiddenError(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
  ): Observable<never> {
    return this.launchDarklyService
      .variation$(FeatureFlagNames.enableCallToOmacs, false)
      .pipe(
        mergeMap((enabled: boolean) => {
          const ignore403 = request.params.has('ignore403');
          if (
            enabled &&
            error.status === httpStatus.FORBIDDEN &&
            this.isRestrictedEndpoint(request.url) &&
            !ignore403
          ) {
            const patientId = request.url.split('/').pop();
            const path = `${environment.memberManagementUi.host}/members/restricted/${patientId}/access_denied`;
            this.logger.info('Rerouting to CAZ workflow error: ' + error);
            window.location.href = path;
          }
          return throwError(error);
        }),
      );
  }

  /**
   * For now we only want to redirect users to CAZ for /api/v2/admin/patients/<id>
   * This will need to be updated as we expand to other endpoints.
   */
  private isRestrictedEndpoint(endpoint: string): boolean {
    const validUrl = URL.canParse(endpoint);
    if (!validUrl) {
      return false;
    }
    const url = new URL(endpoint);
    return this.restrictedEndpointPatterns.some(pattern =>
      pattern.test(url.pathname),
    );
  }
}
